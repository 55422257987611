import { Box, Button, ButtonGroup, Center, Checkbox, Container, Divider, Flex, Heading, HStack, Icon, Link, List, ListIcon, ListItem, Stack, Table, TableContainer, Tag, Tbody, Td, Text, Textarea, Thead, Tr, useColorMode, VStack } from "@chakra-ui/react"
import PageNavbarHeader from "@components/navbar/PageNavbar"
import { tokenConfig } from "@redux/actions/auth-actions/tokenConfig"
import axios from "axios"
import { Suspense, useEffect, useState } from "react"
import { Envelope } from "react-bootstrap-icons"
import { Helmet } from "react-helmet-async"
import { BiLink, BiTrash } from "react-icons/bi"
import { BsCheck2All, BsFileEarmarkRichtext } from "react-icons/bs"
import { GrLocation } from "react-icons/gr"
import { LuFile, LuFileDown, LuImage, LuShare2, LuUser } from "react-icons/lu"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"



const ShowNCR = () => {
    const [ncr, setNcr] = useState(null)
    const [originalNcr, setOriginalNcr] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isScrollingDown, setIsScrollingDown] = useState(false)
    const [isScrollingUp, setIsScrollingUp] = useState(false)

    const { ncr_id } = useParams()
    const { colorMode } = useColorMode()
    const navigate = useNavigate()
    const handleSlectPart = () => {
        navigate(`/parts/${ncr?.part?._id}`)
    }

    const handleFecthNCR = async () => {
        try {
            const token = await tokenConfig()
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/ncr/${ncr_id}`, token)
            setNcr(response?.data?.ncr)
            setOriginalNcr(response?.data?.ncr)
        } catch (error) {
            console.error(error)
            toast.error('An error occurred while fetching NCR.')
        }
    }
    const handleSaveNCR = async () => {
        try {
            const token = await tokenConfig()
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/ncr/${ncr_id}`, ncr, token)
            setNcr(response?.data?.ncr)
            setOriginalNcr(response?.data?.ncr)
            setIsEditing(false)
            toast.success('NCR saved successfully.')
        } catch (error) {
            console.error(error)
            toast.error('An error occurred while saving NCR.')
        }
    }

    const handleCancelNCR = () => {
        setNcr(originalNcr)
        setIsEditing(false)
    }



    const handleChangeDescription = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const description = e.target.value
        setNcr({ ...ncr, description })
        setIsEditing(true)
    }


    useEffect(() => {
        handleFecthNCR()
        setIsEditing(false)
    }, [ncr_id])

    useEffect(() => {
        if (isEditing) {
            window.addEventListener('scroll', () => {
                if (window.scrollY > 0) {
                    setIsScrollingDown(true)
                } else {
                    setIsScrollingDown(false)
                }
                if (window.scrollY > 100) {
                    setIsScrollingUp(true)
                } else {
                    setIsScrollingUp(false)
                }
            }
            )
        }
        return () => {
            window.removeEventListener('scroll', () => {
                if (window.scrollY > 0) {
                    setIsScrollingDown(true)
                } else {
                    setIsScrollingDown(false)
                }
                if (window.scrollY > 100) {
                    setIsScrollingUp(true)
                } else {
                    setIsScrollingUp(false)
                }
            }
            )
        }
    }, [isEditing])

    useEffect(() => {
        if (isEditing) {
            //Prevent close tab or navigate away
            window.onbeforeunload = function () {
                toast.warning('You have unsaved changes. Please save before leaving or discard changes.')
                return true;
            }
        } else {
            window.onbeforeunload = null
        }
    }, [isEditing])




    const menu = [
        {
            id: 'files',
            title: 'Files',
            children: [
                {
                    id: 'files',
                    title: 'Files',
                    icon: <Icon as={LuFileDown} boxSize={6} />,
                    action: () => { }
                },
                {
                    id: 'share',
                    title: 'Share',
                    icon: <Icon as={LuShare2} boxSize={6} />,
                    action: () => { }
                },
            ]
        },

        {
            id: 'links',
            title: 'Links',
            children: [
                {
                    id: 'part',
                    title: `Part ${ncr?.part?.id}`,
                    icon: <Icon as={BiLink} boxSize={6} />,
                    action: handleSlectPart
                },


            ]
        },
        {
            id: 'ncr',
            title: 'NCR',
            children: [
                {
                    id: 'close_ncr',
                    title: 'Close',
                    icon: <Icon as={BsCheck2All} boxSize={6} />,
                    action: () => { }
                },
                {
                    id: 'edit_ncr',
                    title: 'Edit',
                    icon: <Icon as={BsFileEarmarkRichtext} boxSize={6} />,
                    action: () => { }

                },
                {
                    id: 'delete_ncr',
                    title: 'Delete',
                    icon: <Icon as={BiTrash} boxSize={6} />,
                    action: () => { }

                },
            ]
        },
    ]

    return (<Box
        maxW="8xl"
        mx="auto"
        px={{ base: '4', md: '8', lg: '12' }}
        position={'relative'}
        minH={'100vh'}
        p={{ base: '4', md: '8', lg: '12' }}
    >
        <Helmet>
            <title>{`NCR ${ncr?.id || ''}`}</title>
            <meta name="description" content={`NCR ${ncr?.id || ncr_id}`} />
        </Helmet>
        <Container maxW="6xl" bg={colorMode === 'light' ? 'bg.muted' : 'bg.dark'} rounded="lg" position={'relative'}>
            <Box position={'sticky'} top={0} zIndex={10} bg={colorMode === 'light' ? 'bg.muted' : 'bg.dark'} rounded="lg" shadow={'2xl'} mb={10} >
                <Suspense>
                    <PageNavbarHeader
                        menuOptions={menu}
                        title={ncr?.reference || `NCR# ${ncr_id}`}
                        onClose={() => { }} />
                </Suspense>
            </Box>
            <Container maxW="6xl" bg={colorMode === 'light' ? 'bg.muted' : 'bg.dark'} rounded="lg" position={'relative'}>
                {isEditing && <Flex justifyContent={'flex-end'} w={'100%'} position={'sticky'} top={'10vh'} zIndex={10}  >

                    <Flex justifyContent={'flex-end'} gap={4} top={0} right={0} zIndex={10} mb={10} p={4} width={'fit-content'} >

                        <Button colorScheme="orange" variant={'outline'} onClick={handleCancelNCR} >
                            Discard
                        </Button>
                        <Button colorScheme="blue" onClick={handleSaveNCR} >
                            Save
                        </Button>
                    </Flex>
                </Flex>}





                <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '8', md: '20' }}>
                    <Stack gap="6" pt="2" flex="1">
                        <Stack gap={{ base: '6', md: '8' }} >
                            <Stack gap={{ base: '4', md: '5' }} border={`1px dashed ${ncr?.status === 'open' ? 'red' : 'blue'}`} borderColor={'colorPalette.fg'} rounded={'md'} p={4} py={{ base: '8', md: '12' }} position={'relative'} >

                                <Box position={'absolute'} top={2} right={2} zIndex={1}  >
                                    <Tag colorScheme="red" size="lg" variant="outline" textTransform={'uppercase'} >
                                        {ncr?.status || 'Status'}
                                    </Tag>
                                </Box>
                                <Stack alignItems={'center'} gap={{ base: '2', md: '3' }} direction={{ base: 'column', md: 'row' }} p={2} rounded={'md'} w={'fit-content'}>
                                    <Text textStyle={{ base: 'md', md: 'lg' }} fontWeight={'bold'} m={0} color="fg.muted">
                                        NCR {ncr?.id || ncr_id}
                                    </Text>

                                </Stack>
                                <Stack gap={{ base: '2', md: '3' }} alignItems={'center'} >
                                    <Heading as="h2" fontSize={{ base: '4xl', md: '5xl' }} textTransform={'uppercase'} >
                                        {ncr?.part?.id || 'Part ID'} - {ncr?.part?.type}
                                    </Heading>

                                </Stack>
                            </Stack>

                        </Stack>
                    </Stack>

                    <Box flex="1" w="full">
                        <Center w="full" h="full" bg="bg.muted" color="fg.subtle" minH={{ base: 'xxs', md: 'xs'}} rounded="lg" shadow={'xl'}>
                            <LuImage size="48px" />
                        </Center>
                    </Box>
                </Flex>
                <Stack mt={10} direction={{ base: 'column', xl: 'row-reverse' }} justifyContent={'space-between'} >
                    <VStack gap={{ base: '4', md: '6' }} width={{ base: 'full', xl: '45%' }} justifyContent={{ base: 'center', xl: 'flex-start' }} alignItems={{ base: 'center', xl: 'flex-start' }} >
                        <Heading as="h4" fontSize={{ base: 'lg', md: 'xl' }} >
                            {ncr?.reference || 'Reference'}
                        </Heading>
                        <VStack w={'full'} gap={0} justifyContent={{ base: 'center', xl: 'flex-start' }} alignItems={{ base: 'center', xl: 'flex-start' }}> 
                            <Text textStyle={{ base: 'md', md: 'lg' }} fontWeight={'bold'} m={0} color="fg.muted">
                                Description:
                            </Text>
                            <Textarea
                                mt={0}
                                maxH={{ base: 'xs', md: 'sm' }}
                                value={ncr?.description || 'This is a description of the NCR.'}
                                onChange={handleChangeDescription}
                                placeholder='Here is a sample placeholder'
                                size="lg"
                            />
                        </VStack>

                    </VStack>
                    <Stack gap={{ base: '6', md: '8' }} >

                        <Container maxW="4xl" >
                            <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                                Department
                            </Heading>
                            <HStack>
                                <Icon as={GrLocation} boxSize={6} />
                                <Text>
                                    {ncr?.department?.id}
                                </Text>
                            </HStack>
                        </Container>

                        <Container maxW="4xl"  >
                            <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                                Responsibles
                            </Heading>
                            <List spacing={3}>
                                {ncr?.responsibles?.map((responsible, index) => (
                                    <ListItem key={index}>

                                        <ListIcon as={LuUser} color="colorPalette.fg" />
                                        {responsible?.name}
                                    </ListItem>
                                ))}
                            </List>
                        </Container>
                        <Container maxW="4xl"  >
                            <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                                Contacts
                            </Heading>
                            <List spacing={3}>
                                {ncr?.emails?.map((email, index) => (
                                    <ListItem key={index}>
                                        <ListIcon as={Envelope} color="colorPalette.fg" />
                                        {email}
                                    </ListItem>
                                ))}
                            </List>
                        </Container>
                    </Stack>
                </Stack>
                <Divider />
                <Container my={10} maxW="4xl" py={{ base: '2', md: '4' }} >
                    <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                        Files
                    </Heading>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Td>Name</Td>
                                    <Td>Type</Td>
                                    <Td>Size</Td>
                                    <Td>Link</Td>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {ncr?.files?.map((file, index) => (
                                    <Tr key={index}>
                                        <Td><Link href={`https://${file}`} target="_blank" rel="noreferrer" color="blue.500" >{file}</Link></Td>
                                        <Td>PDF</Td>
                                        <Td>1.2MB</Td>
                                        <Td><Button colorScheme="blue" variant="outline" size="sm" leftIcon={<Icon as={LuFileDown} boxSize={4} />} >Download</Button></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Container>

                <Container my={5} maxW="4xl" py={{ base: '2', md: '4' }} >
                    <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                        Immediate Actions
                    </Heading>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Td></Td>
                                    <Td>Action</Td>
                                    <Td>Responsible</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ncr?.immediateActions?.map((action, index) => (
                                    <Tr key={index}>
                                        <Td><Checkbox isChecked={false} /></Td>
                                        <Td>{action}</Td>
                                        <Td></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Container>


                <Container my={5} maxW="4xl" py={{ base: '2', md: '4' }} >
                    <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                        Corrective Actions
                    </Heading>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Td></Td>
                                    <Td>Action</Td>
                                    <Td>Responsible</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ncr?.correctiveActions?.map((action, index) => (
                                    <Tr key={index}>
                                        <Td><Checkbox isChecked={false} /></Td>
                                        <Td>{action}</Td>
                                        <Td></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Container>

                <Container my={5} maxW="4xl" py={{ base: '2', md: '4' }} >
                    <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                        Root Causes
                    </Heading>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Td></Td>
                                    <Td>Cause</Td>
                                    <Td>Responsible</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ncr?.rootCauses?.map((cause, index) => (
                                    <Tr key={index}>
                                        <Td><Checkbox isChecked={false} /></Td>
                                        <Td>{cause}</Td>
                                        <Td></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Container>

                <Container my={5} maxW="4xl" py={{ base: '2', md: '4' }} >
                    <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} >
                        Preventive Actions
                    </Heading>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Td></Td>
                                    <Td>Action</Td>
                                    <Td>Responsible</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ncr?.preventiveActions?.map((action, index) => (
                                    <Tr key={index}>
                                        <Td><Checkbox isChecked={false} /></Td>
                                        <Td>{action}</Td>
                                        <Td></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Container>

            </Container>
        </Container>
    </Box>)
}

export default ShowNCR