import { Route, Routes, useNavigate, } from 'react-router-dom';



import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './redux/actions/auth-actions/loadUser';
import { Suspense, lazy, useEffect } from 'react';
import GeneralSpinner from '@components/GeneralSpinner';
import { Box, VStack } from '@chakra-ui/react';
import { Logo } from '@components/logo/Logo';
import { v4 as uuid } from 'uuid';
import UploadFilesPage from '@pages/files/UploadFilesPage';
import WorkstationPage from '@pages/workstation/WorkstationPage';
import { ComunicationCenterLayout } from '@layouts/comunication-center/ComunicationCenterLayout';
import { TravelersDangerZone } from '@pages/traveler/TravelersDangerZone';
import ShowNCR from '@layouts/ncr/ShowNcr';



const LoginPage = lazy(() => import('@pages/login/LoginPage'));
const CreateAccountPage = lazy(() => import('@pages/account/CreateAccountPage'));
const VerifyEmailAddressPage = lazy(() => import('@pages/account/VerifyEmailAddressPage'));
const UserAccountInfo = lazy(() => import('@pages/account/UserAccountInfo'));
const HelpAndSupportPage = lazy(() => import('@pages/help/HelpAndSupportPage'));

const DashboardPage = lazy(() => import('@pages/dashboard/DashboardPage'));
const HomePage = lazy(() => import('@pages/home/HomePage'));

const SalesPage = lazy(() => import('@pages/sales/SalesPage'));
const SaleOrderForm = lazy(() => import('@layouts/sale/SaleOrderForm'));
const ShowSaleOrder = lazy(() => import('@layouts/sale/ShowSaleOrder'));
const SaleOrderCSVImportProcessForm = lazy(() => import('@layouts/sale/SaleOrderCSVImportProcessForm'));

const PayloadPage = lazy(() => import('@pages/payload/PayloadPage'));
const ShowPayloadItem = lazy(() => import('@layouts/payload/ShowPayloadItem'));

const TravelersPage = lazy(() => import('@pages/traveler/TravelersPage'));
const ShowTraveler = lazy(() => import('@layouts/traveler/ShowTraveler'));
const TrackTravelerPage = lazy(() => import('@pages/traveler/TrackTravelerPage'));

const StockPage = lazy(() => import('@pages/stock/StockPage'));


const CompanyPage = lazy(() => import('@pages/company/CompanyPage'));
const ShowCompany = lazy(() => import('@layouts/company/ShowCompany'));

const PartsPage = lazy(() => import('@pages/part/PartsPage'));
const ShowPart = lazy(() => import('@layouts/part/ShowPart'));

const TrackingPage = lazy(() => import('@pages/tracking/TrackingPage'));

const NCRMainPage = lazy(() => import('@pages/ncr/NCRMainPage'));
const NCRFormPage = lazy(() => import('@pages/ncr/NCRFormPage'));

const DepartmentsPage = lazy(() => import('@pages/department/DepartmentsPage'));
const ShowDepartment = lazy(() => import('@layouts/department/ShowDepartment'));

const ShippingPage = lazy(() => import('@pages/shipping/ShippingPage'));

const SystemSettingPage = lazy(() => import('@pages/system/SystemSettingPage'));

const ProtectedPage = lazy(() => import('@pages/protected/ProtectedPage'));

const HarnessStatsPage = lazy(() => import('@pages/stats/HarnessStatsPage'));

const Page404 = lazy(() => import('@components/404'));




export const routes = [
  { path: '/', element: <HomePage />, public_route: true, breadcrumb: 'UPM' },
  { path: '/login', element: <LoginPage />, public_route: true, breadcrumb: 'Login' },
  { path: '/signup', element: <CreateAccountPage />, public_route: true, breadcrumb: 'Create Account' },
  { path: 'verify_email_address/:uid?/:code?', element: <VerifyEmailAddressPage />, public_route: true, breadcrumb: 'Verify Email Address' },

  {/*ACCOUNT*/ route: 'by_pass', path: '/account/info', element: <UserAccountInfo />, breadcrumb: 'Account Info' },
  {/*Help&Support */ route: 'by_pass', path: '/help', element: <HelpAndSupportPage />, breadcrumb: 'Help & Support' },

  {/*Dashboard*/ route: 'dashboard', redirect: '/travelers', path: '/dashboard/', element: <DashboardPage />, breadcrumb: 'Dashboard' },

  {/*Company*/          route: 'company', path: '/companies', element: <CompanyPage />, breadcrumb: 'Companies' },
  {/*Company Search*/   route: 'company', path: '/companies/search/:searchValue?', element: <CompanyPage />, breadcrumb: 'Search' },
  {/*Company*/          route: 'company', path: '/companies/select/:company_id?', element: <ShowCompany />, breadcrumb: 'Company' },

  {/*Part*/ route: 'part', path: '/parts', element: <PartsPage />, breadcrumb: 'Parts' },
  {/*Part*/ route: 'part', path: '/parts/search/:searchValue?', element: <PartsPage />, breadcrumb: 'Search' },
  {/*Part*/ route: 'part', path: '/parts/:part_id', element: <ShowPart />, breadcrumb: 'Part Selected' },


  {/*Department*/ route: 'department', path: '/departments', element: <DepartmentsPage />, breadcrumb: 'Departments' },
  {/*Department*/ route: 'department', path: '/departments/:department_id', element: <ShowDepartment />, breadcrumb: 'Department' },
  
  
  {/*Wrokstation*/ route: 'workstation', path: '/workstations', element: <WorkstationPage />, breadcrumb: 'Workstations' },
  {/*Wrokstation*/ route: 'workstation', path: '/workstations/create/:department_id?/:department_name?/:row?/:column?', element: <WorkstationPage />, breadcrumb: 'Create Workstation' },
  {/*Wrokstation*/ route: 'workstation', path: '/workstations/search/:searchValue?', element: <WorkstationPage />, breadcrumb: 'Workstations' },
  {/*Wrokstation*/ route: 'workstation', path: '/workstations/:workstation_id', element: <WorkstationPage />, breadcrumb: 'Workstation' },
  {/*Wrokstation*/ route: 'workstation', path: '/workstations/:workstation_id/traveler/:traveler_id', element: <WorkstationPage />, breadcrumb: 'Workstation' },


  {/*Sale Order*/ route: 'saleorder', path: '/sales', element: <SalesPage />, breadcrumb: 'Sales' },
  {/*Sale Order*/ route: 'saleorder', path: '/sales/search/:searchValue?', element: <SalesPage />, breadcrumb: 'Sales' },
  {/*Sale Order*/ route: 'saleorder', path: '/sales/create', element: <SaleOrderForm />, breadcrumb: 'Create Sale Order' },
  {/*Sale Order*/ route: 'saleorder', path: '/sales/csv/import/:company_id?', element: <SaleOrderCSVImportProcessForm />, breadcrumb: 'Import Sale Orders' },
  {/*Sale Order*/ route: 'saleorder', path: '/sales/:sale_order_id/edit', element: <SaleOrderForm />, breadcrumb: 'Edit Sale Order' },
  {/*Sale Order*/ route: 'saleorder', path: '/sales/:sale_order_id/edit/payloaditem/:item_id', element: <SaleOrderForm />, breadcrumb: 'Edit Payload Item' },


  {/*Sale Order*/ route: 'saleorder', path: '/sales/:sale_order_id', element: <ShowSaleOrder />, breadcrumb: 'Order' },
  {/*Sale Order*/ route: 'saleorder', path: '/sales/:sale_order_id/payloaditem/:item_id', element: <ShowSaleOrder />, breadcrumb: 'Payload' },

  {/*Payload*/ route: 'payload', path: '/payloads', element: <PayloadPage />, breadcrumb: 'Payloads' },
  {/*Payload*/ route: 'payload', path: '/payloads/search/:searchValue?', element: <PayloadPage />, breadcrumb: 'Payloads' },
  {/*Payload*/ route: 'payload', path: '/payloads/:payload_item_id', element: <ShowPayloadItem />, breadcrumb: 'Payload' },

  {/*Traveler*/  route: 'traveler', path: '/travelers', element: <TravelersPage />, breadcrumb: 'Travelers' },
  {/*Traveler*/  route: 'traveler', path: '/travelers/search/:searchValue?', element: <TravelersPage />, breadcrumb: 'Travelers' },
  {/*Traveler*/  route: 'traveler', path: '/travelers/:traveler_id', element: <ShowTraveler />, breadcrumb: 'Travelers Selected' },
  {/*Traveler*/  route: 'traveler', path: '/traveler/tracking/:traveler?/:tracking_code?/:_id?', element: <TrackTravelerPage />, breadcrumb: 'Traveler Tracking' },
  {/*Traveler*/  route: 'traveler', path: '/travelers/danger-zone', element: <TravelersDangerZone />, breadcrumb: 'Danger Zone' },

  {/*Shipping*/ route: 'shipping', path: '/shippings', element: <ShippingPage />, breadcrumb: 'Shipping' },
  {/*Shipping*/ route: 'tracking', path: '/tracking', element: <TrackingPage />, breadcrumb: 'Tracking' },

  {/*Stock*/ route: 'stock', path: '/stock', element: <StockPage />, breadcrumb: 'Stock' },

  {/*NCR*/ route: 'ncr', path: '/ncr', element: <NCRMainPage />, breadcrumb: 'NCR' },
  {/*NCR*/ route: 'ncr', path: '/ncr/:ncr_id', element: <ShowNCR />, breadcrumb: 'NCR' },
  {/*NCR*/ route: 'ncr', path: '/ncr/form', element: <NCRFormPage />, breadcrumb: 'NCR' },
  {/*NCR*/ route: 'ncr', path: '/ncr/form/part/:part_id?', element: <NCRFormPage />, breadcrumb: 'NCR' },
  {/*NCR*/ route: 'ncr', path: '/ncr/form/traveler/:traveler_id?', element: <NCRFormPage />, breadcrumb: 'NCR' },


  {/*Harness Stats*/ route: 'stats_harness', path: '/stats/harness', element: <HarnessStatsPage />, breadcrumb: 'Harness Stats' },

  {/*Upload Files*/ route: 'by_pass', path: '/files/upload', element: <UploadFilesPage />, breadcrumb: 'Upload Files' },

  //Lab Settings
  {/*Settings*/ route: 'by_pass', path: '/settings', element: <SystemSettingPage />, breadcrumb: 'Settings' },
  {/*Settings*/ route: 'by_pass', path: '/comunication', element: <ComunicationCenterLayout />, breadcrumb: 'Comunication' },

];

export const APPRoute = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    const cancelSubscription = dispatch(loadUser());
    return () => cancelSubscription;
  }, []);

  const { user = null, loading, } = useSelector(state => state.userrr);

  // this method to control 404 not found page
  const generateRoute = (path, compt, public_route, route, redirect) => {

    if (!loading && user && user?.routesAvailables) {
      if (user && !public_route) {
        if (user?.routesAvailables && user?.routesAvailables?.find(r => r?.trim()?.toLowerCase() === route || route === 'by_pass')) {
          return <Route key={uuid()} path={path} element={<ProtectedPage>{compt}</ProtectedPage>} exact />;
        } else if (!!redirect) {
          return <Route key={uuid()} path={path} element={<Redirect to={redirect} />} exact />;
        }
      } else {
        return <Route key={uuid()} path={path} element={<LoginPage />} exact />;
      }
    }
  };

  if (loading) {
    return (<Box w={'full'} h={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <VStack my={25}>
        <GeneralSpinner />
        <Logo />
      </VStack>
    </Box>)
  }
  return (<Box minH={'90vh'}>
    <Suspense fallback={<GeneralSpinner />} >
      <Routes>
        {routes.map(({ path, element, public_route = false, route, redirect }) => {
          if (public_route)
            return (<Route key={uuid()} path={path} element={element} exact />)
          return generateRoute(path, element, public_route, route, redirect)
        })}

        <Route path="*" element={<Page404 />} />
        {/*Services*/}

      </Routes>
    </Suspense></Box>)
}


const Redirect = ({ to }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [to])

  return (<VStack w={'full'} h={'full'}>
    <GeneralSpinner />
    <h1>
      Redirecting...
    </h1>
    <Logo />


  </VStack>)
}