
import {
    Box,
    CloseButton,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
    Stack,
    Heading,
    useColorModeValue,
    Flex,
    Text,
    Button,
    useBreakpointValue,
    VStack,
    ButtonGroup,


} from '@chakra-ui/react'

import PropTypes from 'prop-types';
import React from 'react';
import { ThreeDots } from 'react-bootstrap-icons';

export default function PageNavbarHeader({ header, image, title, barHeader = <></>, search = <></>, ...rest }) {
    let navbarPosition = "relative";
    let navbarFilter = "none";
    let secondaryMargin = "0px";
    let gap = "0px";
    return (
        <Box
            as="nav"
            zIndex={5}
            p={2}
            className={`${title}-header`}
            position={navbarPosition}
            filter={navbarFilter}
            mt={secondaryMargin}
            mb={gap}

        >
            {/**Navbar header */}
            <Box
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                flexDirection={{ base: 'column', md: 'row' }}
                fontSize={{
                    base: 'sm',
                    md: 'md',
                    xl: 'lg',
                }}
                w={'100%'}
            >
                {header}
            </Box>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                display={'flex'}
                alignItems={{
                    base: 'start',
                    md: 'center',
                }}
                justifyContent={'space-between'}
                px={'3%'}
            >
                {/**Title*/}
                <VStack justifyContent={'flex-start'} alignItems={'start'}>
                    {!!title && <Text ml={10} textTransform={'uppercase'} fontFamily={'monospace'} fontSize={{
                        base: 'large',
                        md: 'x-large',
                        lg: 'xx-large',


                    }} size={{ base: 'sm', md: 'md' }} fontWeight="semibold">
                        {title}
                    </Text>}
                    {!!barHeader && <Box>
                        {barHeader}
                    </Box>}
                </VStack>
                {image}

                {/**Right menu with search into it */}
                <RightMenu secondary={true} {...rest}>
                    {search}
                </RightMenu>



            </Stack >
        </Box>

    );
}



const RightMenu = (props) => {
    const { onClose, menuOptions, reference, actions = [], children, secondary, } = props;

    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    return (
        <Flex
            transition={'1s ease'}
            my={2}
            mr={8}
            px={2}
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            justifyContent={'space-evenly'}
            flexDirection="row"
            flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
            py={1}
            borderRadius="35px"
        >
            {!!menuOptions && (Array.isArray(menuOptions)) && <Menu isLazy >
                <MenuButton p="0px" as={IconButton} rounded={'full'} icon={<ThreeDots />} me="10px" />
                <MenuList
                  
                    boxShadow={shadow}
                    p="20px"
                    borderRadius="20px"
                    border="none"
                    mt="22px"
                    me={{ base: '30px', md: 'unset' }}
                    minW={{ base: 'unset', md: '200px', xl: '300px' }}
                    maxW={{ base: '360px', md: 'unset' }}
                >
                    {menuOptions.map((option) => <MenuParent key={option.id} item={option} />)}
                </MenuList>
            </Menu>}
            {!!reference && reference}

            <Box mx={2} maxW={{
                base: '65%',
                md: '2xs'
            }}>
                {children}
            </Box>


            <ButtonGroup spacing={0}>
                {actions?.map(action => <ButtonAnimated
                    key={action?.id}
                    aria-label={action?.title}
                    colorScheme={action?.color || 'blue'}
                    rounded={'full'}
                    icon={action?.icon}
                    onClick={action?.action}
                    {...action?.props}
                    text={action?.title}
                >

                </ButtonAnimated>
                )}
            </ButtonGroup>
            {!!onClose && <CloseButton size={'lg'} onClick={!!onClose ? onClose : () => { }} />}

        </Flex>
    )
}
const ButtonAnimated = ({ text, icon, children, ...props }) => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    return (<Box
        cursor={'pointer'}
        transition={'all 0.8s ease-in-out width 1.8s ease-in-out'}
        p={1}
    //_hover={{'p': { transition: 'all 0.8s ease-in-out', width: 'inherit', p: 1, visibility: 'visible', opacity: 1 },}} there is an issue in mobile 
    > <Button
        rounded={'full'}
        leftIcon={icon}
        {...props}
        me="10px"
    >
            <Text rounded={'lg'} m={0} p={isMobile ? 0 : 1} visibility={isMobile ? 'hidden' : 'visible'} opacity={isMobile ? 0 : 1} w={isMobile ? '0px' : 'inherit'} fontSize="sm" fontWeight="600">
                {text}
            </Text>
            {children}
        </Button>
    </Box>)
}


const MenuParent = ({ item }) => {
    if (item?.children?.children)
        return (<MenuParent item={item?.children} />)
    if (!item?.children)
        return <MenuItem {...item} my={2} icon={item?.icon} onClick={item?.action} >
            {item?.title}
        </MenuItem>
    return (<><MenuGroup title={item.title}>
        {item?.children.map((child, index) =>
            <MenuItem {...child} my={2} key={`${item.title}-${child?.title}`} onClick={child?.action} icon={child?.icon}>
                {child?.title || index}
            </MenuItem>)}
    </MenuGroup>
        <MenuDivider />
    </>
    )
}




PageNavbarHeader.propTypes = {
    image: PropTypes.element,
    title: PropTypes.string.isRequired,
    reference: PropTypes.string,
    menuOptions: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
    actions: PropTypes.arrayOf(PropTypes.object),
    search: PropTypes.element,
    secondary: PropTypes.bool,

}

RightMenu.propTypes = {
    reference: PropTypes.string,
    menuOptions: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
    actions: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.element,
    secondary: PropTypes.bool,
}